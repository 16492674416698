




































































































































































































import useEvent from "@/use/event";
import useSelectItems from "@/use/selectItems";
import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  watch,
} from "@vue/composition-api";
import { AxiosInstance } from "axios";
import { DataOptions } from "vuetify";
import { v4 as uuid } from "uuid";
import useMisc from "@/use/misc";

export default defineComponent({
  components: {
    MEventDelete: () =>
      import("@/components/molecules/event/m-event-delete.vue"),
  },
  setup(_, { root }) {
    const {
      getEventTypeColor,
      getEventTypeName,
      getEventStatus,
      getEventStatusColor,
      getEventStatusName,
    } = useEvent({ root });
    const { eventStatusItemsLite, eventTypeItems } = useSelectItems({ root });
    const { goBack } = useMisc({ root });

    const state = reactive({
      show: false,
      loaded: true,
      nowDate: "",
      defaultSort: "startDate",
      headers: computed(() => [
        {
          value: "index",
          text: root.$tc("layout.misc.orderNumber"),
          width: 1,
          sortable: false,
        },
        {
          text: root.$t("layout.event.list.name"),
          value: "name",
          sortable: false,
        },
        {
          text: root.$t("layout.event.list.type"),
          value: "type",
          sortable: false,
          align: "center",
        },
        {
          text: root.$t("layout.event.list.startDate"),
          value: "startDate",
          align: "center",
        },
        {
          text: root.$t("layout.event.list.endDate"),
          value: "endDate",
          align: "center",
        },
        {
          text: root.$t("layout.event.list.status"),
          value: "status",
          align: "center",
        },
        { text: "", value: "website", sortable: false, align: "center" },
        { text: "", value: "messages", sortable: false, align: "center" },
        { text: "", value: "members", sortable: false, align: "center" },
        {
          text: "",
          value: "registration",
          sortable: false,
          align: "center",
          width: 1,
        },
        { text: "", value: "coordinator", sortable: false, align: "center" },
        { text: "", value: "actions", sortable: false, align: "center" },
      ]),
      options: {
        page: 1,
        itemsPerPage: 25,
        sortBy: ["startDate"],
        sortDesc: [false],
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: false,
      },
      loading: false,
      empty: false,
      items: [],
      total: 0,
      dataLoaded: false,
      search: "",
      searchTimeout: undefined as any,
      error: false,
      showHidden: false,
    });

    const model = reactive({
      status: "incoming",
      type: "",
    });

    const nowDate = () => {
      state.nowDate = new Date().toISOString().slice(0, 10);
    };

    const onRowClick = (event: MouseEvent, data: { item: { id: string } }) => {
      goBack("panel.event.view.info", { id: data.item.id });
    };

    onMounted(nowDate);

    const fetchData = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;
      const {
        sortBy,
        sortDesc,
        page,
        itemsPerPage,
      } = state.options as DataOptions;

      state.loading = true;

      axiosInstance
        .get("event", {
          params: {
            sortBy,
            sortDesc,
            page,
            itemsPerPage,
            status: model.status,
            type: model.type || undefined,
            search: state.search || undefined,
          },
        })
        .then(({ data: { events, total } }) => {
          state.empty = false;
          state.items = events.filter(
            (item: any) => item.isHidden === (state.showHidden ?? false)
          );
          state.total = total;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            state.empty = true;
            state.items = [];
            state.total = 0;
          } else {
            console.log(error);
          }
        })
        .finally(() => {
          state.dataLoaded = true;
          state.loading = false;
        });
    };

    onMounted(fetchData);

    watch(() => model.status, fetchData);
    watch(() => model.type, fetchData);
    watch(() => state.options, fetchData, { deep: true });
    watch(
      () => state.search,
      (newSearch) => {
        state.search = newSearch;
        clearTimeout(state.searchTimeout);
        state.searchTimeout = setTimeout(fetchData, 500);
      }
    );
    watch(
      () => model.status,
      () => {
        if (model.status === "incoming") {
          state.options.sortDesc = [false];
        } else {
          state.options.sortDesc = [true];
        }
      }
    );

    watch(() => state.showHidden, fetchData);

    const hasAccessTo = (permission: string) =>
      root.$store.getters["user/hasAccessTo"](permission);

    const eventVisibility = (item: any, type: string) => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      const data = {
        name: item.name,
        type: item.type,
        startDate: item.startDate,
        endDate: item.endDate,
        startTime: item.startTime,
        endTime: item.endTime,
        endRegistrationDate: item.endRegistrationDate,
        isHidden: type === "hidden" ? true : false,
      };

      state.loading = true;

      axiosInstance
        .put(`event/${item.id}`, data, {
          headers: {
            event: item.id,
          },
        })
        .then(() => {
          root.$store.commit("snackbar/PUSH_MESSAGE", {
            id: uuid(),
            color: "primary",
            message:
              type === "hidden"
                ? root.$tc("panel.event.successAddToHidden")
                : root.$tc("panel.event.successAddToVisible"),
          });
          state.error = false;
          fetchData();
        })
        .catch((error) => {
          state.error = error.response.status;
          root.$store.commit("snackbar/PUSH_MESSAGE", {
            id: uuid(),
            color: "error",
            message:
              type === "hidden"
                ? root.$tc("panel.event.errorAddToHidden")
                : root.$tc("panel.event.errorAddToVisible"),
          });
        })
        .finally(() => (state.loading = false));
    };

    const blockEvent = (item: any, type: string) => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .patch(`event/${item.id}/lock`)
        .then(() => {
          root.$store.commit("snackbar/PUSH_MESSAGE", {
            id: uuid(),
            color: "primary",
            message:
              type === "block"
                ? root.$tc("panel.event.successBlockEvent")
                : root.$tc("panel.event.successUnblockEvent"),
          });
          state.error = false;
          fetchData();
        })
        .catch((error) => {
          state.error = error.response.status;
          root.$store.commit("snackbar/PUSH_MESSAGE", {
            id: uuid(),
            color: "error",
            message:
              type === "block"
                ? root.$tc("panel.event.errorBlockEvent")
                : root.$tc("panel.event.errorUnblockEvent"),
          });
        })
        .finally(() => (state.loading = false));
    };

    return {
      state,
      getEventTypeColor,
      getEventTypeName,
      getEventStatus,
      getEventStatusColor,
      getEventStatusName,
      onRowClick,
      eventStatusItemsLite,
      eventTypeItems,
      model,
      hasAccessTo,
      eventVisibility,
      blockEvent,
      fetchData,
    };
  },
});
